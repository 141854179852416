.contain{
    margin-bottom: 150px;
}
.head{
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 500;
    color: #ff4c0a;
    margin-bottom: 13px;
}
.our{
    display: inline;
    font-size: 34px;
    font-weight: 500;
    color: #000000;
    font-weight: bold;
}
.study{
    display: inline;
    font-size: 34px;
    font-weight: bold;
    color: #ff4c0a;
}
.grp_btn{
    margin-top: 40px;
    margin-bottom: 40px;
}

.filter{
    display: inline;
    margin-left: 25px;
    margin-right: 25px;
    font-family: 'Poppins';
    font-size: 20px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
}
.filter:hover{
    color: #ff4c0a;
    font-weight: bold;
}
.active{
    color: #ff4c0a;
    font-weight: bold;
}
.img{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/caseStudy/gradient.png"), linear-gradient(#eb01a5, #d13531);
    border-radius: 10px;
    cursor: pointer;
}
.img:hover{
    transform: scale(1.04);
    transition: .5s;
}
.imgText1{
    font-family: 'Poppins';
    font-size: 14px;
    color: white;
    text-align: left;
    padding-top: 74%;
    padding-left: 30px;
    margin-bottom: 2px;
}
.imgText2{
    font-family: 'Poppins';
    font-size: 22px;
    color: white;
    font-weight: bold;
    text-align: left;
    padding-left: 30px;
    padding-bottom: 15px;
}

@media screen and (max-width: 992px) {
    .head{
        font-size: 25px;
    }
    .our, .study{
        font-size: 30px;
    }
    .filter{
        font-size: 18px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .imgText2{
        font-size: 18px;
        padding-left: 20px;
    }
    .imgText1{
        padding-left: 20px;
    }
    .grp_btn{
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    .head{
        font-size: 18px;
    }
    .our, .study{
        font-size: 22px;
    }
    .filter{
        font-size: 14px;
        margin-left: 8px;
        margin-right: 8px;
    }
    .grp_btn{
        margin-top: 25px;
        margin-bottom: 25px;
    }
}