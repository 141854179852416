.img{
    width: 100%;
}
.heading{
    font-size: 30px;
    font-weight: 500;
    color: #ff4c0a;
    font-family: 'Poppins';
    text-align: left;
    font-weight: 500;
}
.subHeading{
    font-size: 34px;
    font-family: 'Poppins';
    font-weight: bold;
    text-align: left;
    width: 400px;
    margin-bottom: 45px;
}
.content{
    font-size: 15px;
    font-family: 'Poppins';
    text-align: left;
    color: #6d7783;
    width: 80%;
    text-align: justify;
    margin-bottom: 40px;
}
.btn{
    width: 272px;
    height: 64px;
    font-size: 14px;
}
.text{
    margin-left: 50px;
    margin-top: 50px;
}

@media screen and (max-width:1200px) {
    .text{
        margin-top: 20px
    }
}

@media screen and (max-width:1100px) {
    .heading{
        font-size: 25px;
    }
    .subHeading{
        font-size: 28px;
        width: 300px;
    }
    .btn{
        height: 50px;
        width: 200px;
    }
}
@media screen and (max-width:950px) {
    .imgDiv{
        margin-top: 100px;
    }
}

@media screen and (max-width:767px) {
    .imgDiv{
        margin-top: 0px;
    }
    .heading{
        font-size: 20px;
    }
    .subHeading{
        font-size: 22px;
        margin-bottom: 22px;
    }
}
@media screen and (max-width:500px) {
    .heading{
        font-size: 18px;
    }
    .subHeading{
        font-size: 20px;
        margin-bottom: 20px;
        width: 70%;
    }
    .content{
        margin-bottom: 23px;
    }
}

@media screen and (max-width:355px) {
    .subHeading{
        width: 100%;
    }
}